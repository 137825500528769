import React from "react"
import { graphql } from "gatsby"

import Page from "../components/page"
import PodcastBanner from "../components/podcast-banner"
import PodcastsGrid from "../components/podcasts-grid"

const PodcastPage = props => {
  const { data } = props
  const { podcastsData } = data
  const { podcasts } = podcastsData
  return (
    <Page title="Podcast" heroContent={<PodcastBanner layout="horizontal" />}>
      <section>
        <PodcastsGrid posts={podcasts} />
      </section>
    </Page>
  )
}

export const pageQuery = graphql`
  query PodcastsIndexQuery {
    podcastsData: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "podcast" } } }
      sort: { order: DESC, fields: [fields___date] }
    ) {
      ...Podcasts
    }
  }
`

export default PodcastPage
